import React, { useEffect, useState } from "react";
import Data from "./files/p4plist.json";
import SupportPalestine from "./files/supportPalestine.png";
import Flag from "./files/flag.png";

import { Container, Row, Col, Form, Card, Image } from "react-bootstrap";

function App() {
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState([]);
  const [showData, setShowData] = useState([]);
  const getCategories = (data) => {
    const categories = [];
    if (data && data.length) {
      data.map((item, i) => {
        if (item.Category && categories.indexOf(item.Category) === -1) {
          categories.push(item.Category);
        }
      });
      return categories;
    }
  };
  const filterUpdate = (category) => {
    const index = filter.indexOf(category);
    if (index === -1) {
      setFilter([...filter, category]);
    } else {
      const x = [...filter];
      x.splice(index, 1);
      setFilter([...x]);
    }
  };

  const search = (e) => {
    const keyword = e.currentTarget.value;
    const newList = [];
    if (Data && Data.length) {
      Data.map((item) => {
        if (
          item?.da?.display_text &&
          item?.da?.display_text
            .toLocaleLowerCase()
            .indexOf(keyword.toLocaleLowerCase()) !== -1
        ) {
          newList.push(item);
        }
      });
    }

    setShowData(newList);
  };

  useEffect(() => {
    if (Data && Data.length) {
      setShowData(Data);
      setCategories(getCategories(Data));
    }
  }, []);
  useEffect(() => {
    if (filter && filter.length && Data && Data.length) {
      setShowData([]);
      const newData = [];
      Data.map((item) => {
        if (filter.indexOf(item.Category) !== -1) {
          newData.push(item);
        }
      });
      setShowData(newData);
    } else {
      setShowData(Data);
    }
  }, [filter]);

  const DataShower = () => {
    if (showData?.length) {
      return showData.map((item) => {
        return (
          <Card
            className="mx-auto"
            style={{ width: "18rem" }}
            key={item?.da?.display_text}
          >
            <Card.Img
              variant="top"
              src={(item.Logolink && item.Logolink) || SupportPalestine}
            />
            <Card.Body className="d-flex flex-column">
              <Card.Title>{item?.da?.display_text}</Card.Title>
              <Card.Text>
                <span style={{ fontSize: "16px", fontWeight: "800" }}>
                  {" "}
                  Category:
                </span>
                {" " + item.Category}
              </Card.Text>

              {item.ShortBio && (
                <Card.Text>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    Bio:{" "}
                  </span>
                  {item.ShortBio}
                </Card.Text>
              )}
              {item?.da?.url && (
                <a
                  target="__blank"
                  href={item?.da?.url}
                  className="btn btn-primary mt-auto"
                >
                  Visit website
                </a>
              )}
            </Card.Body>
          </Card>
        );
      });
    } else {
      return <p>There is no projects...</p>;
    }
  };

  return (
    <>
      <Container className="pt-2 py-5 mx-auto" fluid>
        <Row className="mx-auto pb-3">
          <Col className="bg-white mx-auto" xs="10">
            <Row className="mx-auto">
              <Col className="mx-auto text-center mb-3">
                <Image
                  style={{ filter: "drop-shadow(5px 5px 10px #000000)" }}
                  className="mx-auto img-fluid"
                  src={Flag}
                ></Image>
              </Col>
            </Row>

            <Row className="mx-auto my-3">
              <Col className="mx-auto mb-3">
                {categories &&
                  categories.length &&
                  categories.map((category) => {
                    return (
                      <div
                        key={category}
                        className="mx-2 d-inline-block form-check"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value={category}
                          onChange={(e) => filterUpdate(category)}
                        />

                        <label title="" className="form-check-label">
                          <b>{category}</b>
                        </label>
                      </div>
                    );
                  })}
              </Col>
            </Row>

            <Row className="mx-auto my-3">
              <Col className="col-lg-3 col-md-4 col-sm-10 mx-auto mb-3">
                <div key={"sss"} className="mx-2 form-inline">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => search(e)}
                    placeholder="Search..."
                  />
                </div>
              </Col>
            </Row>

            <Row className="mx-auto pb-5">
              <Col className="mx-auto">
                <Row xs={1} md={4} className="g-4 mx-auto">
                  <DataShower></DataShower>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <footer className="mt-5 mb-3">
        <div className="container text-center">
          <b>
            Done by{" "}
            <a
              style={{ textDecoration: "none" }}
              target="__blank"
              href="https://techforpalestine.org/"
            >
              {"Tech for Palestine"}
            </a>
          </b>
        </div>
      </footer>
    </>
  );
}

export default App;
